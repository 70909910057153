
import { Vue, Component } from "vue-property-decorator";
import { apiSdvideoConfig, apiSdvideoConfigEdit } from "@/api/setting";

@Component
export default class SettingRecord extends Vue {
    /** S Data **/
    // 表单数据
    form: any = {
        host_name: "http://127.0.0.1:7860/",      // 服务器地址
        randomSeed: 1,        // 随机种子
        samplingSteps: 25,    // 采样步数
        guidanceScale: 7.5,   // 相关性
        comfy_host: "http://127.0.0.1:8188/",     // 服务器地址
        comfy_steps: 20,      // 步数
        comfy_scale: 2.0,     // 相关性
        anyone_frame_cap: 60,      // 最大帧数	
        anyone_frame_rate: 15,     // 合成帧数	
        anyone_steps: 10,          // 步数	
        anyone_cfg: 3.5,           // cfg	
        anyone_context_frames: 12,           // 上下文帧数	
        anyone_sampler: "DDIM",              // 采样	
        anyone_lora_name: "",                // 真人lora	
        anyone_img_width: 512,               // 宽	
        anyone_img_height: 768,              // 高	
    };

    // 表单验证
    rules: object = {

    };
    /** E Data **/

    /** S Methods **/
    // 初始化表单数据
    async initFormData(): Promise<void> {
        const res: any = await apiSdvideoConfig()
        for (const key in res) {
            this.form[key] = res[key];
        }
    }

    // 提交表单
    onSubmitFrom(formName: string) {
        const refs = this.$refs[formName] as HTMLFormElement;

        refs.validate(async (valid: boolean): Promise<void> => {
            if (!valid) return;
            const loading = this.$loading({ text: "保存中" });

            await apiSdvideoConfigEdit({
                ...this.form,
            }).finally(() => {
                loading.close();
            });
            this.$store.dispatch("getConfig");
        });
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.initFormData();
    }
    /** E Life Cycle **/
}
